import React, { useState } from 'react';
import ReactSlider from 'react-slider';
// import ReactSlider from 'react-slider'

const RangeSlider = ({ setProductList, productList, product }) => {
  const [value, setValue] = useState([0, 500]); // Initial value

  // const handleChange = (e) => {
  //   setValue(e.target.value);
  // };

  return (
    <div className="w-full">
      <ReactSlider
        className="horizontal-slider h-4 "
        // thumbClassName="example-thumb"
        // trackClassName="example-track bg-blue-800"
        defaultValue={[0, 500]}
        min={0}
        max={500}
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={state => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => <div   {...props}>
          <div className='rounded-full h-4 w-4 cursor-pointer bg-blue-700 text-white'>
            {/* {state.valueNow} */}
          </div>
        </div>}
        renderTrack={(trackProps, state) => {
          const points = Array.isArray(state.value) ? state.value.length : null;
          const isMulti = points && points > 0;

          const isLast = isMulti ? state.index === points : state.index === 1;
          const isFirst = state.index === 0;

          return (<div
            {...trackProps}
            className={`top-1/2 h-1 -translate-y-1/2 rounded ${isFirst || isLast ? 'bg-black/30' : 'bg-blue-700'
              }`}
          ></div>)
        }}
        pearling
        step={10}
        minDistance={30}
        onChange={(e) => setValue(e)}
      />
      {/* <input
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
        className="w-full h-2 bg-black rounded-full outline-none"
      /> */}
      <div className='flex w-full justify-between items-center'>
        <p className='text-base'>Price ${value[0]} --- ${value[1]}</p>
        <button
          onClick={() => {
            const filterProducts = product?.filter(product => {
              const price = parseFloat(product.price); // Convert string price to float
              console.log("price", price)
              return price >= value[0] && price <= value[1];
            })
            setProductList(filterProducts)
          }}
          // type="submit"
          className="bg-black text-white px-4 py-2 rounded-md hover:scale-110 transition my-4"
        >
          Filter
        </button>
      </div>
    </div>
  );
};

export default RangeSlider;