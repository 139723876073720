import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Brands from "./Pages/Brands";
import Cigarettes from "./Pages/Cigarettes";
import RYO from "./Pages/RYO";
import Cigars from "./Pages/Cigars";
import Vapings from "./Pages/Vapings";
import Accessories from "./Pages/Accessories";
import Contact from "./Pages/Contact";
import AboutUs from "./Pages/AboutUs";
import SignIn from "./Pages/SignIn";
import Layout from "./components/Layout.js";
import AgeVerification from "./Pages/AgeVerification.js";
import { useEffect, useState } from "react";
import Terms from "./Pages/Terms.js";
import MyState from "./data/myState.js";
import ProductDetailPage from "./Pages/ProductDetailPage.js";
import PaymentPage from "./Pages/PaymentPage.js";
import Cart from "./Pages/Cart.js";
import MyAccountPage from "./Pages/MyAccountPage.jsx";
import "./App.css";

function App() {
  const [storedAge, setStoredAge] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true

  useEffect(() => {
    // Simulating a fetch from localhost
    setTimeout(() => {
      // After 3 seconds, set isLoading to false
      setIsLoading(false);
    }, 200);

    // Check local storage for userAge
    var a = localStorage.getItem("userAge");
    if (a) {
      setStoredAge(a);
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
        <style jsx>{`
          .loader {
            border-top-color: #18ade8;
            border-bottom-color: #18ade8;
            border-left-color: transparent;
            border-right-color: transparent;
            animation: spin 1.5s linear infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </div>
    );
  }

  const user = localStorage.getItem("userAuth");
  const localUser = user ? JSON.parse(user) : null;

  return (
    <>
      {storedAge < 18 ? (
        <AgeVerification />
      ) : (
        <Router>
          <MyState>
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/cigarettes" element={<Cigarettes />} />
                <Route path="/ryo" element={<RYO />} />
                <Route path="/cigar" element={<Cigars />} />
                <Route path="/vaping" element={<Vapings />} />
                <Route path="/accessories" element={<Accessories />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/cart" element={<Cart />} />
                <Route path={"/product/:id"} element={<ProductDetailPage />} />
              </Routes>
              {localUser && (
                <Routes>
                  <Route path="/payment" element={<PaymentPage />} />
                  <Route path="/my-account" element={<MyAccountPage />} />
                </Routes>
              )}
            </Layout>
          </MyState>
        </Router>
      )}
    </>
  );
}

export default App;
