import React, { useContext } from "react";
import HeroSection from "../components/HeroSection";
import warningImage from "../images/improvementImage.jpg";
import ProductCard from "../components/ProductCard/ProductCard";
import myContext from "../data/myContext";
import { Link } from "react-router-dom";

function Home() {
  const context = useContext(myContext);
  const { product } = context;

  // console.log(product)

  return (
    <>
      <HeroSection />
      <div className="container mx-auto max-w-screen-xl">
        <img className="my-12 px-6" src={warningImage} alt="Improvement" />

        <div className="container mx-auto text-center">
          <h2 className="text-black text-center mb-8 text-4xl font-bold">
            Shop Cigarettes
          </h2>
          <a
            href={"/cigarettes"}
            className="text-[#1aaee7] text-center  font-medium text-xl"
          >
            View All Cigarettes
          </a>
        </div>
        <div className="p-4 grid grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 justify-start items-center gap-4 ">
          {product
            .filter((item) => item.category === "cigarettes")
            .slice(0, 8)
            .map((item, index) => {
              // alert(typeof(item.category))
              // return item.category === "cigarettes" ? (
              return (
                <ProductCard
                  key={index}
                  title={item.name}
                  price={item.price}
                  id={item.id}
                  image={item.image}
                  product={item}
                />
              );
              // ) : null;
            })}
        </div>

        <div className="container mx-auto md:mt-24 text-center">
          <h2 className="text-primary text-center mb-8 text-4xl font-bold">
            Shop RYO
          </h2>
          <a
            href={"/ryo"}
            className="text-[#1aaee7] text-center  font-medium text-xl"
          >
            View All RYO
          </a>
        </div>
        <div className="p-4 grid grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 justify-start items-center gap-4 ">
          {product
            .filter((item) => item.category === "ryo")
            .slice(0, 8)
            .map((item, index) => {
              // alert(typeof(item.category))
              // return item.category === "ryo" ? (
              return (
                <ProductCard
                  key={index}
                  title={item.name}
                  price={item.price}
                  id={item.id}
                  image={item.image}
                  product={item}
                />
              );
              // ) : null;
            })}
        </div>

        <div className="container mx-auto md:mt-24 text-center">
          <h2 className="text-primary text-center mb-8 text-4xl font-bold">
            Shop Vape
          </h2>
          <a
            href={"/vaping"}
            className="text-[#1aaee7] text-center  font-medium text-xl"
          >
            View All Vape products
          </a>
        </div>
        <div className="p-4 grid grid-cols-2 lg:grid-cols-3  2xl:grid-cols-4 justify-start items-center gap-4 ">
          {product
            .filter((item) => item.category === "vape")
            .slice(0, 8)
            .map((item, index) => {
              // alert(typeof(item.category))
              // return item.category === "vape" ? (
              return (
                <ProductCard
                  key={index}
                  title={item.name}
                  price={item.price}
                  id={item.id}
                  image={item.image}
                  product={item}
                />
              );
              // ) : null;
            })}
        </div>

        <img className="my-12 px-6" src={warningImage} alt="Improvement" />
      </div>
    </>
  );
}

export default Home;
