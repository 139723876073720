import React from 'react'
// import WhatsAppIcon from '../WhatsappIcon'
// import IllegaToSellTobaco from '../IllegaToSellTobaco/index'
import AboutImage from '../images/illegalwarning.png'
import warningImage from '../images/improvementImage.jpg'


function AboutUs() {

  return (
    <>
      <div className='my-6 lg:my-24'>
        <div className='container mx-auto mb-2 lg:mb-8'>
          <h1 className='font-bold text-xl lg:text-[45px] text-primary mx-4 lg:x-0'>About Us</h1>
        </div>
        <div className='container mx-auto lg:flex justify-between gap-6 lg:my-6 '>
          <img
            className='rounded rounded-lg md:max-w-[40%] px-4 lg:mx-0 z-[19px] relative'
            src={AboutImage}
            alt="Logo"
 
          />

          <div className='md:text-base xl:text-2xl text-black mx-4 lg:mx-0 my-8 lg:my-16'>
            <div className=''>
              <p className='mb-12'>
                <span className='font-semibold'>Discount Smokes</span> is based in Melbourne. With many years of experience in tobacco retailing, we have recently expanded the business online to serve all customers in Australia. As a responsible company, we are only serving adult customers.
              </p>
              <p className='mb-12'>
                Every product we sell is 100% genuine, sourced from the leading suppliers in the industry. We are a one-stop online store for cigarettes, tobacco, cigar, vapes, and accessories.
              </p>
              <p>
                Being easy to use, with a full product range, competitive pricing, and fast delivery is the key to our business. With  <span className='font-semibold'>Discount Smokes</span>, having your smokes delivered to your door has never been easier.
              </p>
            </div>
          </div>
        </div>
      </div>

      <img className='my-12 px-6' src={warningImage} alt="Improvement Image" />
      {/* <WhatsAppIcon /> */}
      {/* <IllegaToSellTobaco /> */}
    </>
  )
}

export default AboutUs