import React, { useContext, useEffect, useState } from "react";
// import ProductCard from './ProductCard/ProductCard'
import RangeSlider from "./RangeSlider";
// import WhatsAppIcon from './WhatsappIcon'
import warningImage from "../images/improvementImage.jpg";
import ProductCard from "./ProductCard/ProductCard";
import myContext from "../data/myContext";

export const BrandsName = [
  "ABC Brand",
  "Benson & Hedges",
  "Bond Street",
  "Capstan",
  "Captain Black",
  "Choice",
  "Chesterfield",
  "Champion",
  "Craftsman",
  "Henri Wintermans",
  "Horizon",
  "JPS",
  "Longbeach",
  "Marlboro",
  "Pall Mall",
  "Parker & Simpson",
  "Peter Stuyvesant",
  "Peter Jackson",
  "Port Royal",
];

export const Sizes = [
  { label: "20s", value: 20 },
  { label: "23s", value: 23 },
  { label: "25s", value: 25 },
  { label: "30s", value: 30 },
  { label: "40s", value: 40 },
  { label: "100s", value: 100 },
  { label: "200s", value: 200 },
];

function ProductFilter({ heading }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);

  const context = useContext(myContext);
  const { product } = context;

  const [productList, setProductList] = useState(product);

  useEffect(() => {
    // console.log("first", product)
    setProductList(product);
  }, [product]);

  // console.log(productList)

  function handleSizeChange(size, value) {
    const newSizes = size
      ? [...selectedSizes, size]
      : selectedSizes.filter((prevSize) => prevSize !== value);
    setSelectedSizes(newSizes);
    // const filterSizes = newSizes.map((size) => product.filter((item) => item.size.includes(size)))
    // setProductList(filterSizes)
  }
  function handleBrandChange(brand, value) {
    const newBrands = brand
      ? [...selectedBrands, brand]
      : selectedBrands.filter((prevBrand) => prevBrand !== value);
    setSelectedBrands(newBrands);
    // const filterBrands = newBrands.map((brand) => product.filter((item) => item.brand.includes(brand)))
    // setProductList(filterBrands)
    // setSelectedBrands((prev) => brand ? [...prev, brand] : prev.filter((prevBrand) => prevBrand !== value))
    // const a = product.filter((item) => item.brand.includes(brand))
    // console.log(a)
    // setProductList(a)
  }

  function filterProducts() {
    const filterBrands = selectedBrands.flatMap((brand) =>
      product.filter((item) => item.brand.includes(brand))
    );
    const filterSizes = selectedSizes.flatMap((size) =>
      product.filter((item) => item.size.includes(`${size}s`))
    );
    const newArray = [...filterBrands, ...filterSizes];
    setProductList(() => (newArray?.length > 0 ? newArray : product));
    // setProductList(filterBrands)
  }

  useEffect(() => {
    filterProducts();
  }, [selectedBrands, selectedSizes]);

  return (
    <>
      <div className="my-6 mx-6 lg:my-24">
        <div className="container mx-auto mb-2 px-4 lg:mb-8">
          <h1 className="font-bold text-xl lg:text-5xl text-black">
            {heading}
          </h1>
          <h2 className="text-xl font-bold mt-2">{`Home > ${heading}`}</h2>
        </div>

        <div className="container mx-auto">
          <div className="grid grid-cols-12 gap-4">
            {/* First div with grid width 4 */}
            <div className="col-span-12 md:col-span-4">
              {/* Your content goes here */}
              <div className="w-[80%] mx-auto my-6">
                <div className="my-2">
                  <h2 className="font-semibold text-base lg:text-2xl">
                    Filter by Price
                  </h2>
                  <RangeSlider
                    setProductList={setProductList}
                    productList={productList}
                    product={product}
                  />
                </div>
                {heading !== "Cigars" ? (
                  <>
                    <div className="my-2">
                      {/* <h2 className='font-semibold text-2xl mb-4'>Filter by Price</h2> */}

                      <div className="border rounded-md p-4 my-2">
                        <button
                          className="flex justify-between items-center w-full"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <h2 className="font-semibold text-base lg:text-2xl">
                            Filter by Size
                          </h2>
                          <span className="text-gray-600">
                            {isOpen ? "-" : "+"}
                          </span>
                        </button>
                        {isOpen && (
                          <p className="mt-2">
                            {Sizes.map((item, index) => (
                              <CheckboxWithText
                                name={item.label}
                                value={item.value}
                                onChange={(size) =>
                                  handleSizeChange(size, item.value)
                                }
                              />
                            ))}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="border rounded-md p-4 my-2">
                        <button
                          className="flex justify-between items-center w-full"
                          onClick={() => setIsBrandOpen(!isBrandOpen)}
                        >
                          <h2 className="font-semibold text-base lg:text-2xl">
                            Filter by Brand
                          </h2>
                          <span className="text-gray-600">
                            {isBrandOpen ? "-" : "+"}
                          </span>
                        </button>
                        {isBrandOpen && (
                          <p className="mt-2">
                            {BrandsName.map((item) => (
                              <CheckboxWithText
                                name={item}
                                value={item}
                                onChange={(brand) =>
                                  handleBrandChange(brand, item)
                                }
                              />
                            ))}
                            {/* <CheckboxWithText name={'Alpine'} />
                                                <CheckboxWithText name={'Escort'} />
                                                <CheckboxWithText name={'Alpine'} />
                                                <CheckboxWithText name={'Alpine'} />
                                                <CheckboxWithText name={'Alpine'} /> */}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/* Second div with grid width 8 */}
            <div className="col-span-12 md:col-span-8">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                {productList.map((item) => {
                  // alert(typeof(item.category))
                  if (heading === "Brands") {
                    return (
                      <ProductCard
                        title={item.name}
                        price={item.price}
                        id={item.id}
                        image={item.image}
                        product={item}
                      />
                    );
                  } else {
                    return item.category === heading.toLowerCase() ? (
                      <ProductCard
                        title={item.name}
                        price={item.price}
                        id={item.id}
                        image={item.image}
                        product={item}
                      />
                    ) : null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="my-12 px-6 mx-auto"
        src={warningImage}
        alt="Improvement"
      />
    </>
  );
}

export default ProductFilter;

const CheckboxWithText = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    !isChecked ? props.onChange(props.value) : props.onChange(undefined);
  };

  return (
    <>
      <div className="flex items-center space-x-2 mt-2">
        <input
          type="checkbox"
          // id="checkbox"
          checked={isChecked}
          value={props.value}
          onChange={handleCheckboxChange}
          className="w-5 h-5 border checked:bg-black checked:border-transparent focus:outline-none focus:ring focus:border-black"
        />
        <label htmlFor="checkbox" className="text-gray-700">
          {props.name}
        </label>
      </div>
      {/* <WhatsAppIcon /> */}
    </>
  );
};
