import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import myContext from "../data/myContext";
import { doc, getDoc } from "firebase/firestore";
import { FireDB } from "../firebase";
import Swal from "sweetalert2";
// import banner from "../images/Banner.png";
import banner from "../images/banner2.jpg";

// import MyContext from "../../../context/myContext";

const ProductDetailPage = () => {
  const { id } = useParams();

  const context = useContext(myContext);
  const { setCartItem, cartItem } = context;

  const [productById, setProductById] = useState([]);
  const [count, setCount] = useState(1);

  const addToCartFunction = (productToAdd) => {
    const existingProduct = cartItem.find(
      (item) => item.id === productToAdd.id
    );

    if (existingProduct) {
      // If the product exists, update its count
      const updatedCartItems = cartItem.map((item) =>
        item.id === productToAdd.id
          ? { ...item, count: item.count + count }
          : item
      );
      setCartItem(updatedCartItems);
      // handleSetCartItem(updatedCartItems);
    } else {
      // If the product doesn't exist, add it to the cart with count 1
      setCartItem([...cartItem, { ...productToAdd, count: count }]);
      // handleSetCartItem([...cartItem, { ...productToAdd, count: count }]);
    }

    // setCartItem([...cartItem, product])
    console.table(cartItem);

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Product Added to Cart",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const getProductById = async (productId) => {
    // alert(productId)
    if (!productId) {
      throw new Error("getProductById requires a product ID argument");
    }

    // setLoading(true);

    try {
      const productDoc = await getDoc(doc(FireDB, "products", productId)); // Fetch specific product by ID

      if (!productDoc.exists) {
        alert(`Product with ID "${productId}" not found`);
        setProductById(null); // Set product to null if not found
      } else {
        const productData = productDoc.data();
        setProductById({ ...productData, id: productId, count: 0 }); // Add ID to product data
        // console.log(productData)
      }

      //   setLoading(false);
    } catch (error) {
      console.error("Error fetching product:", error);
      //   setLoading(false);
    }
  };

  useEffect(() => {
    getProductById(id);
    window.scrollTo(0, 0);
    // console.log("here", productById)
  }, [id]);

  return (
    <div className="container mx-auto p-4 max-w-screen-lg">
      {/* <div className='container mt-6 mx-auto px-4 lg:px-0 my-8 lg:my-16'>
                <img
                    src=''
                    alt="image is here"
                    className="w-full p-12 rounded-lg mb-4 hover:shadow-xl transition duration-700 transform hover:scale-105"
                />
            </div> */}

      <div className="flex flex-col md:flex-row my-12 gap-12">
        <div className="md:w-1/2 md:mr-4">
          <img
            src={productById.image}
            alt="product-img"
            className="w-full  rounded-lg mb-4 hover:shadow-xl transition duration-700 transform hover:scale-105"
          />
        </div>
        <div className="md:w-1/2 h-1/3 md:mt-[12%]">
          <h1 className="text-3xl font-bold mb-2">{productById.name}</h1>
          {/* <p className="text-gray-700 mb-4">Pall Mall 20s cigarettes in a packet, priced at $33.00 with SKU 111101.</p> */}
          <p className="text-xl font-bold mb-2">
            ${productById.price}{" "}
            <span className="text-base">Price include all taxes</span>
          </p>
          {/* <Link to="/cart"> */}

          <div className="flex items-center border w-fit my-5">
            <button
              className=" h-8 w-8 bg-gray-300"
              onClick={() => setCount((prev) => (prev > 1 ? prev - 1 : 1))}
            >
              -
            </button>
            <p className=" h-full w-12 text-center">{count}</p>

            <button
              className=" h-8 w-8 bg-gray-300"
              onClick={() =>
                setCount((prev) =>
                  Number(productById.stock) > prev ? prev + 1 : prev
                )
              }
            >
              +
            </button>
          </div>
          {productById?.stock > 0 ? (
            <button
              onClick={() => addToCartFunction(productById)}
              className="bg-[#18ade9] text-white px-4 py-2 rounded hover:bg-black transition duration-300 transform hover:scale-105"
            >
              Add to Cart
            </button>
          ) : (
            <div className="text-red-500">Out of stock</div>
          )}
          <img src={banner} alt="delivery banner" className="mt-5" />
          {/* </Link> */}
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="table-auto border-collapse border border-gray-400 bg-white w-6/12">
          {/* <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2 text-start">Attribute</th>
              <th className="px-4 py-2 text-start">Value</th>
            </tr>
          </thead> */}
          <tbody>
            <tr>
              <td className="px-4 py-2 border border-gray-400">Size</td>
              <td className="px-4 py-2 border border-gray-400">
                {productById.size}
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-400">Price</td>
              <td className="px-4 py-2 border border-gray-400">
                ${productById.price}
              </td>
            </tr>
            {/* <tr>
              <td className="px-4 py-2 border border-gray-400">Stock</td>
              <td className="px-4 py-2 border border-gray-400">
                {productById.stock}
              </td>
            </tr> */}
            <tr>
              <td className="px-4 py-2 border border-gray-400">Categories</td>
              <td className="px-4 py-2 border border-gray-400">
                {productById.category}
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2 border border-gray-400">Brand</td>
              <td className="px-4 py-2 border border-gray-400">
                {productById.brand}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductDetailPage;
