import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import WhatsAppIcon from './WhatsAppIcon'

function Layout({children}) {
  return (
    <>
    <Navbar />
    <main>{children}</main>
    <WhatsAppIcon />
    <Footer />
    </>
  )
}

export default Layout
