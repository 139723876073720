import React, { useEffect, useState } from 'react';
import backgroundImg from '../images/aboutusimage.png';
import Swal from 'sweetalert2';
import Logo from '../images/logo.png'

const AgeVerification = () => {
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const months = Array.from({ length: 12 }, (_, index) => index + 1);
  const years = Array.from({ length: 100 }, (_, index) => 2024 - index);

  // Set initial values for day, month, and year
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [ageError, setAgeError] = useState('');

  const calculateAge = (e) => {
    e.preventDefault();

    // Check if the selected values are null
    if (selectedDay === null || selectedMonth === null || selectedYear === null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Fill all fields!',
      });
      return;
    } else {
      setAgeError('');
    }

    const currentDate = new Date();
    const birthDate = new Date(selectedYear, selectedMonth - 1, selectedDay);
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    if (age > 18) {
      localStorage.setItem('userAge', age.toString());
      window.location.href = '/';
      // navigate('/');
      // forceUpdate();
      setSelectedYear(null);
      setSelectedMonth(null);
      setSelectedDay(null);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Not Allowed to access!',
      });
    }
  };

  return (
    <div
      className="h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="bg-black p-8 rounded-xl shadow-lg text-white text-center w-auto lg:w-[30%]">
        <img
          className="w-40 my-8 mx-auto"
          src={Logo}
          alt="Logo"
        />
        <h1 className="text-[22px] font-bold mb-6">You must be 18+ to enter</h1>
        <form>
          <div className="flex justify-center gap-1 lg:gap-2">
            <DD options={days} selectedOption={selectedDay} onChange={setSelectedDay} />
            <MM options={months} selectedOption={selectedMonth} onChange={setSelectedMonth} />
            <YY options={years} selectedOption={selectedYear} onChange={setSelectedYear} />
          </div>
          <button
            onClick={calculateAge}
            type="submit"
            className="bg-[#18ade8] text-white px-4 py-2 rounded-md hover:scale-110 transition my-4"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AgeVerification;



const DD = ({ options, selectedOption, onChange }) => {
    const allOptions = [null, ...options]; // Adding null as the default option
    return (
      <select
        className="border py-2 lg:py-4 px-2 rounded-md w-full text-black"
        value={selectedOption}
        onChange={(e) => onChange(e.target.value)}
      >
        {allOptions.map((option) => (
          <option key={option} value={option}>
            {option === null ? "DD" : option}
          </option>
        ))}
      </select>
    );
  };
const MM = ({ options, selectedOption, onChange }) => {
    const allOptions = [null, ...options]; // Adding null as the default option
    return (
      <select
        className="border py-2 lg:py-4 px-2 rounded-md w-full text-black"
        value={selectedOption}
        onChange={(e) => onChange(e.target.value)}
      >
        {allOptions.map((option) => (
          <option key={option} value={option}>
            {option === null ? "MM" : option}
          </option>
        ))}
      </select>
    );
  };
const YY = ({ options, selectedOption, onChange }) => {
    const allOptions = [null, ...options]; // Adding null as the default option
    return (
      <select
        className="border py-2 lg:py-4 px-2 rounded-md w-full text-black"
        value={selectedOption}
        onChange={(e) => onChange(e.target.value)}
      >
        {allOptions.map((option) => (
          <option key={option} value={option}>
            {option === null ? "YY" : option}
          </option>
        ))}
      </select>
    );
  };