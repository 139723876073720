import React, { useState } from 'react';
import Swal from 'sweetalert2';
import warningImage from '../images/improvementImage.jpg'
import discountlogo from '../images/discountlogo.png'
// import WhatsAppIcon from '../WhatsappIcon';


function ContactUs() {

    const whatsappInbox = () => {
        const whatsappNumber = '+923166726692';
        // Redirect to the WhatsApp chat box
        window.open(`https://wa.me/${whatsappNumber}`, '_blank');
    }

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);

        Swal.fire({
            position: "center",
            icon: "success",
            title: "Your message has been sent",
            showConfirmButton: false,
            timer: 1500
        });

        setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
        });
    };

    return (
        <>
            <div className='my-6 px-6 lg:my-24'>
                <div className='container mx-auto mb-2 lg:mb-8'>
                    <h1 className='font-bold text-xl lg:text-4xl text-primary mx-4 lg:mx-0'>Contact Us</h1>
                </div>
                <div className='container mx-auto lg:flex justify-between lg:my-6 px-4 lg:px-0'>
                    <form onSubmit={handleSubmit} className='w-full lg:w-[60%] mx-auto md:mx-0 '>
                        <div className='my-8'>
                            <input
                                placeholder='Your Name*'
                                className='bg-[#EBEBEB] p-4 w-full shadow-lg'
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='my-8'>
                            <input
                                placeholder='Email*'
                                className='bg-[#EBEBEB] p-4 w-full shadow-lg'
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='my-8'>
                            <input
                                placeholder='Mobile*'
                                className='bg-[#EBEBEB] p-4 w-full shadow-lg'
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='my-8'>
                            <textarea
                                placeholder='How Can We Help?'
                                className='bg-[#EBEBEB] p-4 h-32 w-full shadow-lg'
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>

                        <button type="submit" className='shadow-lg bg-black px-12 rounded py-2 text-white font-bold text-lg hover:scale-105 transition-transform duration-300'>
                            Send
                        </button>
                    </form>
                    <div className='my-8 lg:my-0'>
                        <img
                            className='w-full lg:max-w-[60%] md:max-w-[40%] mx-auto'
                            src={discountlogo}
                            alt="Logo"

                        />
                        <div className='text-base font-semibold my-8 lg:text-left w-[50%] mx-auto'>
                            <a href='https://maps.app.goo.gl/YrMVfveWecyVH7LYA' target='_blank'>
                                Melbourne, Australia.
                            </a>
                        </div>
                        {/* <div className='text-base font-semibold lg:text-left  w-[50%] mx-auto'>
                            <a href="tel:+0468778388" className='underline underline-offset-1'>0468 778 388</a>
                        </div> */}
                        <div className='text-base font-semibold lg:text-left  w-[50%] mx-auto'>
                            <h3 className='font-bold text-black'>Contact us via Email</h3>
                            <a href="mailto:info@discountsmokes.com.au">info@discountsmokes.com.au</a>
                        </div>
                        <div className='text-base font-semibold lg:text-left  w-[50%] mx-auto'>
                            <h3 className='font-bold text-black'>Contact us via WhatsApp</h3>
                            <span className='cursor-pointer' onClick={whatsappInbox}>+923166726692</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='container mx-auto px-4 lg:px-0 my-8 lg:my-16'> */}
            <img className='my-12 px-6' src={warningImage} alt="Improvement Image" />
            {/* </div> */}
            {/* <WhatsAppIcon /> */}
        </>
    );
}

export default ContactUs;
