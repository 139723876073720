import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import twitter from "../../images/twitter.svg";

function Footer() {
  return (
    <footer className="bg-[#040608]">
      <div className="container mx-auto py-8 lg:py-16">
        {/* Logo and Navigation */}
        <div className="flex justify-between gap-12 items-center px-12">
          <a href="/" className="text-white mx-auto lg:mx-0 text-lg font-bold">
            <img className="h-12 lg:h-16" src={Logo} alt="Logo" />
          </a>

          {/* Navigation Links */}
          <div className="hidden lg:block text-white font-semibold text-sm lg:text-base">
            <div className="flex gap-8">
              <a className="py-2" href="/">
                Home
              </a>
              <a className="py-2" href="/brands">
                Brands
              </a>
              <a className="py-2" href="/cigarettes">
                Cigarettes
              </a>
              <a className="py-2" href="/cigar">
                Cigars
              </a>
              <a className="py-2" href="/ryo">
                RYO
              </a>
              <a className="py-2" href="/vaping">
                Vapes
              </a>
              <a className="py-2" href="/accessories">
                Accessories
              </a>
              <a className="py-2" href="/about">
                About
              </a>
              <a className="py-2" href="/contact">
                Contact Us
              </a>
              <a className="py-2" href="/terms">
                Term & Conditions
              </a>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="w-[90%] mx-auto">
          <div className="lg:hidden text-white font-semibold text-sm mt-8 mx-12 md:mx-0">
            <div className="flex flex-col gap-2">
              <a className="py-2 w-32 mx-auto text-center" href="/">
                Home
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/brands">
                Brands
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/cigarettes">
                Cigarettes
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/cigar">
                Cigars
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/ryo">
                RYO
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/vaping">
                Vapes
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/accessories">
                Accessories
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/about">
                About us
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/contact">
                Contact
              </a>
              <a className="py-2 w-32 mx-auto text-center" href="/terms">
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>

        <div className="flex  gap-5 justify-center items-center my-5 lg:justify-start px-12">
          <img src={facebook} alt="facebook-logo" className="h-10 w-10" />
          <img src={instagram} alt="facebook-logo" className="h-10 w-10" />
          <img src={twitter} alt="facebook-logo" className="h-10 w-10" />
        </div>
      </div>

      {/* Footer Content */}
      <div className="container md:flex justify-between mx-auto text-white text-xs lg:text-sm font-medium pb-4 px-12">
        <p className="text-center lg:text-left">
          Copyright © 2024 Discount Smokes PTY LTD
        </p>
        <p className="text-center lg:text-left hover:underline hover:underline-offset-1">
          Our prices are generally cheaper than Woolworths, Coles, and other
          tobacco retailers.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
