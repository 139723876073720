import React from 'react'
import ProductFilter from '../components/ProductFilter'

function Vapings() {
  return (
    <>
      <ProductFilter heading={"Vape"} />
    </>
  )
}

export default Vapings
