import React, { createContext, useEffect, useState } from "react";
import MyContext from "./myContext.js";
import { FireDB, storage, auth } from "../firebase.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
// import toast from 'react-hot-toast';
// import Loader from '../common/Loader/index.tsx';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function MyState(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({
    title: "",
    price: "",
    size: "",
    profit: "",
    brand: "",
    category: "",
    imgUrl: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  // const [cartItem, setCartItem] = useState([]);
  const [count, setCount] = useState(0);
  // console.log("context  = "+cartItem)
  // cartItem? sessionStorage.setItem('cartitems',  JSON.stringify(cartItem)) : '';

  const setCartItem = (products) => {
    setCount(count + 1);
    localStorage.setItem("cartItems", JSON.stringify(products));
  };
  const cartItem = (() => {
    const products = localStorage.getItem("cartItems");
    return products ? JSON.parse(products) : [];
  })();

  const addProduct = async (e) => {
    e.preventDefault();

    if (
      products.title === "" ||
      products.price === "" ||
      products.category === ""
    ) {
      return Swal.fire({
        position: "center",
        icon: "error",
        title: "Your must have to fill all fields",
        showConfirmButton: true,
        timer: 3000,
      });
    }
    const productRef = collection(FireDB, "products");
    try {
      // setLoading(true);
      await addDoc(productRef, products);
      setLoading(false);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your product has been saved",
        showConfirmButton: false,
        timer: 2000,
      });
      // setLoading(false);
      setTimeout(() => {
        // window.location.href = "/products";
        navigate("/products");
      }, 100);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    // Resetting each field to their initial state
    setProducts({
      title: "",
      price: "",
      size: "",
      profit: "",
      brand: "",
      category: "",
      stock: "",
      imgUrl: "",
      time: Timestamp.now(),
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    });
  };

  // User Authentications *****************************************
  const [users, setUsers] = useState({
    email: "",
    password: "",
    state: "",
    time: Timestamp.now(),
  });

  const signUpUser = async (e) => {
    try {
      const userAuth = await createUserWithEmailAndPassword(
        auth,
        e.email,
        e.password
      );

      const userRef = doc(FireDB, "users", userAuth.user.uid);
      await setDoc(userRef, {
        ...e,
        id: userAuth.user.uid,
        createAt: new Date(),
        updatedAt: new Date(),
      });

      await Swal.fire({
        position: "center",
        icon: "Success",
        title: "Sign Up successfully",
        showConfirmButton: true,
        timer: 3000,
      });
      // window.location.href = "/signin";
      navigate("/siginin");
    } catch (error) {
      return Swal.fire({
        position: "center",
        icon: "Error",
        title: `Sign Up Failed ${error?.message}`,
        showConfirmButton: true,
        timer: 3000,
      });
    }
  };

  // SignIn User ******************************
  const [signInUserCredentials, setSignInUserCreadentials] = useState({
    email: "",
    password: "",
  });

  const signInUser = async (e) => {
    try {
      const result = await signInWithEmailAndPassword(
        auth,
        e.email,
        e.password
      );
      const user = await getDoc(doc(FireDB, "users", result.user.uid));
      localStorage.setItem("userAuth", JSON.stringify(user.data()));
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Sign in successfully`,
        showConfirmButton: true,
        timer: 3000,
      });
      // navigate("/my-account");
      window.location.href = "/my-account";
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "Error",
        title: `Sign Up Failed ${error}`,
        showConfirmButton: true,
        timer: 3000,
      });
    }
  };

  const [product, setProduct] = useState([]);

  // ****** get product

  const getProductData = async () => {
    setLoading(true);
    try {
      const q = collection(FireDB, "products");

      const data = onSnapshot(q, (QuerySnapshot) => {
        let productsArray = [];
        QuerySnapshot.forEach((doc) => {
          productsArray.push({ ...doc.data(), id: doc.id });
        });
        setProduct(productsArray);
        setLoading(false);
      });
      return () => data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // ************get unique product

  const [productById, setProductById] = useState([]);
  const getProductById = async (productId) => {
    // alert(productId)
    if (!productId) {
      throw new Error("getProductById requires a product ID argument");
    }

    setLoading(true);

    try {
      const productDoc = await getDoc(doc(FireDB, "products", productId)); // Fetch specific product by ID

      if (!productDoc.exists) {
        alert(`Product with ID "${productId}" not found`);
        setProductById(null); // Set product to null if not found
      } else {
        const productData = productDoc.data();
        setProductById({ ...productData, id: productId }); // Add ID to product data
        console.log(productById);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching product:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  return (
    <MyContext.Provider
      value={{
        addProduct,
        products,
        product,
        setProducts,
        signUpUser,
        signInUser,
        signInUserCredentials,
        setSignInUserCreadentials,
        setUsers,
        users,
        getProductById,
        setProductById,
        productById,
        cartItem,
        setCartItem,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
