import React from 'react';

const Terms = () => {
    return (
        <div className="max-w-4xl mx-auto p-4 my-12">
            <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
            <p>Welcome to Discount Smokes! These terms and conditions outline the rules and regulations for the use of our website and the sale of our smoking products.</p>
            <p>By accessing this website and/or purchasing our smoking products, we assume you accept these terms and conditions. Do not continue to use Discount Smokes if you do not agree to all of the terms and conditions stated on this page.</p>

            <h2 className="text-xl font-bold mt-4">Underage Sales Prohibition:</h2>
            <p>We strictly prohibit the sale of smoking products to individuals under the legal smoking age in their respective jurisdiction. By purchasing our products, you confirm that you are of legal smoking age in your area. We reserve the right to verify the age of customers and refuse service to anyone who does not meet the legal smoking age requirement.</p>

            <h2 className="text-xl font-bold mt-4">Product Information:</h2>
            <p>We strive to provide accurate product descriptions, including details on materials, dimensions, and any relevant specifications. However, we do not guarantee that all product descriptions are entirely accurate, complete, reliable, or error-free. Any reliance on the material provided on this site is at your own risk.</p>
            <p>The images of the products on our website are for illustrative purposes only. The actual products may vary slightly from those images.</p>

            <h2 className="text-xl font-bold mt-4">Pricing and Payment:</h2>
            <p>All prices for our smoking products are listed in Doller and are subject to change without notice. We reserve the right to modify or discontinue any product at any time without prior notice.</p>
            <p>Payment for all orders must be made in full at the time of purchase. We accept [payment methods] for online transactions.</p>

            <h2 className="text-xl font-bold mt-4">Shipping and Delivery:</h2>
            <p>We aim to process and ship orders promptly after receiving payment confirmation. However, we cannot guarantee specific delivery dates or times.</p>
            <p>Shipping costs and estimated delivery times are provided at the time of checkout and may vary based on the shipping destination and selected shipping method.</p>

            <h2 className="text-xl font-bold mt-4">Returns and Exchanges:</h2>
            <p>Due to the nature of our products, we do not accept returns or exchanges unless the item received is defective or damaged upon arrival. If you receive a defective or damaged product, please contact us within 2 days of receiving your order to initiate a return or exchange process.</p>
            <p>Customers are responsible for return shipping costs unless otherwise specified by Discount Smokes.</p>

            <h2 className="text-xl font-bold mt-4">Intellectual Property:</h2>
            <p>All content included on this website, including text, graphics, logos, images, and software, is the property of Discount Smokes or its content suppliers and is protected by applicable copyright and trademark laws.</p>
            <p>You may not use, reproduce, modify, or distribute any content from this website without prior written consent from Discount Smokes.</p>

            <h2 className="text-xl font-bold mt-4">Limitation of Liability:</h2>
            <p>In no event shall Discount Smokes, its directors, officers, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our products or website.</p>

            <h2 className="text-xl font-bold mt-4">Governing Law:</h2>
            <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

            <h2 className="text-xl font-bold mt-4">Changes to Terms and Conditions:</h2>
            <p>We reserve the right to revise these terms and conditions at any time without prior notice. By using this website and purchasing our products, you agree to be bound by the current version of these terms and conditions.</p>

            <h2 className="text-xl font-bold mt-4">Contact Information:</h2>
            <p>If you have any questions about these terms and conditions, please contact us at <a href="mailto:info@discountsmokes.com.au">info@discountsmokes.com.au</a>.</p>

            <p className="mt-4">By placing an order through our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>
        </div>
    );
}

export default Terms;
