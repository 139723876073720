import React from 'react'
import ProductFilter from '../components/ProductFilter'

function Cigars() {
  return (
    <>
      <ProductFilter heading={"Cigars"} />
    </>
  )
}

export default Cigars
