import { Link } from 'react-router-dom';
import React, { useState } from 'react';


function ImageCarosule() {
  const slides = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/testing-smoke.appspot.com/o/HeroSecImg.png?alt=media&token=c8220efa-187d-480e-97b4-f27c0a555493',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className='h-[580px] w-full relative group'>
      <div className='w-full h-full relative'>
        {/* Image */}
        <img
          src={slides[currentIndex].url}
          alt='Slide'
          className='w-full h-full object-cover'
        />

        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        {/* Heading */}
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
          <h1 className='text-white text-xl md:text-2xl lg:text-4xl font-bold'>Discount Smokes</h1>
          <h2 className='text-white text-lg md:text-xl lg:text-2xl font-semibold my-2'>Free Express Delivery Australia Wide <br />*Terms & conditions apply*</h2>
          <Link to='/brands'>
            <button
              className="bg-[#18ade8] tracking-wider text-white px-4 py-2 rounded-md hover:scale-110 transition my-4 font-semibold"
            >
              Shop
            </button>
          </Link>
        </div>
      </div>
      {/* Left Arrow */}
      {/* <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div> */}
      {/* Right Arrow */}
      {/* <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div> */}
      <div className='flex top-4 justify-center py-2'>
        {/* Navigation Dots */}
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className='text-2xl cursor-pointer'
          >
            {/* <RxDotFilled /> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageCarosule;
