import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
// import { StaticImage } from 'gatsby-plugin-image'
import ProductImg from "../images/illegalwarning.png";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import myContext from "../data/myContext";

const Cart = () => {
  const context = useContext(myContext);
  const { cartItem, setCartItem } = context;

  const user = localStorage.getItem("userAuth");
  const localUser = user ? JSON.parse(user) : null;

  console.log("cartItem : ", cartItem);

  const [quantity, setQuantity] = useState(0);

  function updateProductCount(id, count) {
    const updateItems = cartItem?.map((e) =>
      e.id === id ? { ...e, count: count } : e
    );
    setCartItem(updateItems);
  }

  return (
    <>
      {cartItem == 0 ? (
        <div className="container mx-auto w-[90%] h-screen lg:w-[80%] py-6 md:py-8 lg:py-20">
          <div className="bg-black rounded-lg mx-3 md:mx-0">
            <p className="text-white text-sm md:text-base lg:text-lg font-medium p-3 md:p-4 lg:p-6">
              Your cart is Empty Right now
            </p>
          </div>
          <div className="my-6 md:my-8 flex items-center justify-center">
            <Link
              to="/"
              className="shadow-lg bg-black px-4 md:px-6 lg:px-12 rounded py-2 md:py-3 lg:py-4 text-white font-bold text-base md:text-lg hover:scale-105 transition-transform duration-300"
            >
              Return to shop
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="w-[90%] mx-auto mt-16 h-screen border-t-2 border-gray-300">
            {/* <ResponsiveTable /> */}
            <table className="w-full rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-transparent text-primary text-lg md:text-xl lg:text-2xl font-normal">
                  <th className="text-left py-3">Product</th>
                  <th className="text-center py-3">Price</th>
                  <th className="text-center py-3">Quantity</th>
                  <th className="text-center py-3">Subtotal</th>
                </tr>
              </thead>
              <tbody className="bg-gray-100">
                {cartItem?.map((item, index) => (
                  <tr key={index} className="border-t border-gray-300">
                    <td className="text-left px-4 py-3">
                      <div className="flex gap-2 py-4 items-center max-w-[50%]">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-10 h-10 object-cover mr-2"
                        />
                        <h3 className="text-xs md:text-lg font-medium">
                          {item.name}
                        </h3>
                      </div>
                    </td>
                    <td className="text-center pl-6 md:pl-0 md:px-4 py-3">
                      ${item.price}
                    </td>
                    <td className="text-center px-4 py-3">
                      <div className="text-center w-full">
                        {/* <button
                                                    type="button"
                                                    onClick={handleDecrease(item.id)}
                                                    // disabled={quantity === 0}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z" clipRule="evenodd" />
                                                    </svg>
                                                </button> */}

                        <div className="flex items-center justify-center gap-4">
                          <div
                            title={`Stock of product ${Number(item.stock)}`}
                            className="w-6 transition-all duration-500 active:scale-[.80] cursor-pointer h-6 p-1 flex items-center justify-center rounded-full hover:bg-slate-300"
                            onClick={() =>
                              item.count < Number(item.stock) &&
                              updateProductCount(item.id, item.count + 1)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </div>

                          <div className="text-sm px-2 font-medium">
                            {item.count}
                          </div>

                          <div
                            title={item.count === 1 && "Quantity Minimum 1"}
                            className="w-6 transition-all duration-500 active:scale-[.80] cursor-pointer h-6 p-1 flex items-center justify-center rounded-full hover:bg-slate-300"
                            onClick={() =>
                              item.count !== 1 &&
                              updateProductCount(item.id, item.count - 1)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M5 12h14"
                              />
                            </svg>
                          </div>
                        </div>
                        {/* <button
                                                    type="button"
                                                    onClick={handleIncrease}
                                                    disabled={quantity === 20}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
                                                    </svg>
                                                </button> */}
                      </div>
                    </td>
                    <td className="text-center px-4 py-3">
                      ${item.count * item.price}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Link
              to={localUser ? "/payment" : "/signin"}
              state={{ cartItem }}
              className="shadow-lg my-4 bg-black px-6 lg:px-12 rounded py-2 lg:py-4 text-white font-bold text-lg hover:scale-105 transition transform duration-300 block w-full text-center"
            >
              Proceed to checkout
            </Link>
          </div>
          {/* <WhatsAppIcon /> */}
        </>
      )}

      {/* {cartItem == 0 ? "" : <Subtotal />} */}

      <div className="container mx-auto px-4 lg:px-0 my-8 lg:my-16">
        {/* <StaticImage
                    className='w-full'
                    src='../../images/warning.jpg'
                    alt="Logo"
                    placeholder="blurred"
                /> */}
      </div>
      {/* <WhatsAppIcon /> */}
    </>
  );
};

// const Subtotal = () => {
//     return (
//         <div className='container mx-auto my-12 flex flex-col lg:flex-row gap-12'>
//             <div className='bg-gray-300 mx-auto w-[90%] md:w-full lg:w-2/3 rounded-lg'>
//                 <div className='p-6'>
//                     <h3 className='text-xl lg:text-2xl font-bold'>Subtotal: $33.20</h3>
//                 </div>
//                 <div className='flex flex-col lg:flex-row justify-between items-center bg-gray-100 px-6 py-12'>
//                     <h3 className='text-xl lg:text-2xl font-bold'>Heading</h3>
//                     <div className=''>
//                         <ul>
//                             <li className="list-disc pl-3">Regular Parcel Auspost</li>
//                             <li className="list-disc pl-3 text-gray-400">
//                                 <span className='text-primary'>Express Parcel Auspost</span>
//                             </li>
//                         </ul>
//                     </div>
//                 </div>
//                 <div className='p-6'>
//                     <h3 className='text-lg'>Shipping to Victoria: <span className='font-bold'>Change Address</span></h3>
//                 </div>
//             </div>
//             <div className='text-sm mx-auto w-[90%] md:w-full lg:w-1/3'>
//                 <h3 className='my-8'>
//                     * Same day, order Dispatch for the order received by 12 pm
//                 </h3>
//                 <h3 className='my-16'>
//                     * Monday through Friday, excluding weekends,<br /> public holidays and long weekend
//                 </h3>
//                 <Link to='/payment' className='shadow-lg bg-black px-6 lg:px-12 rounded py-2 lg:py-4 text-white font-bold text-lg hover:scale-105 transition transform duration-300 block w-full text-center'>
//                     Proceed to checkout
//                 </Link>
//             </div>
//         </div>
//     )
// }

export default Cart;
