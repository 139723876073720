import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import illegalwarning from "../../images/illegalwarning.png";
import myContext from "../../data/myContext";
import Swal from "sweetalert2";

// ProductCard Component
const ProductCard = ({ image, title, price, id, product }) => {
  const context = useContext(myContext);
  const { setCartItem, cartItem } = context;
  const [hover, setHover] = useState(false);

  const addToCartFunction = (productToAdd) => {
    const existingProduct = cartItem.find(
      (item) => item.id === productToAdd.id
    );

    if (existingProduct) {
      // If the product exists, update its count
      const updatedCartItems = cartItem.map((item) =>
        item.id === productToAdd.id ? { ...item, count: item.count + 1 } : item
      );
      setCartItem(updatedCartItems);
    } else {
      // If the product doesn't exist, add it to the cart with count 1
      setCartItem([...cartItem, { ...productToAdd, count: 1 }]);
    }

    // setCartItem([...cartItem, product])
    console.table(cartItem);

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Product Added to Cart",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div className="block w-32 h-60 md:w-64 md:h-80 mx-auto md:mt-6 shadow-[10px] ">
      <div
        className="relative overflow-hidden group"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {/* Product Image */}
        <div className=" relative  flex flex-col justify-center items-center  overflow-hidden h-32 w-32 md:w-64 md:h-64 ">
          <Link to={`/product/${id}`} className="  rounded-xl overflow-hidden">
            <img
              className="object-cover h-full w-full transition duration-300 transform  group-hover:scale-105 rounded-xl "
              src={image ? image : illegalwarning} // Assuming illegalwarning is defined elsewhere
              alt="product"
              // width={252}
              // height={236}
            />
          </Link>
          {hover && product.stock > 0 && (
            <div
              onClick={() => addToCartFunction(product)}
              className="absolute bottom-0 left-0 w-full text-center cursor-pointer transition-all duration-700 bg-[#18ade9] text-white overflow-hidden rounded-b-xl"
            >
              Add to Cart
            </div>
          )}

          {product.stock <= 0 && (
            <div className="absolute bottom-0 left-0 w-full text-center cursor-pointer transition-all duration-700 bg-[#6b7280] text-white overflow-hidden rounded-b-xl">
              Out of stock
            </div>
          )}
        </div>

        {/* Product Information */}
        <div className="p-4 bg-white">
          <h3 className="text-sm md::text-2xl font-bold mb-2">
            {title.slice(0, 30)}
          </h3>
          <h3 className="text-sm md:text-2xl font-bold mb-4">${price}</h3>
          {/* Add to Cart Button */}
          {/* <button
          onClick={handleAddToCart}
          className="w-full bg-blue-500 text-white px-4 py-2 rounded-full focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bottom-0 left-0"
        >
          Add to Cart
        </button> */}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
