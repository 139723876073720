import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Add this import

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB2bFFqTiXZptURbRFGPJ7nEYxdBbzXsQY",
  authDomain: "discount-smoke-ce754.firebaseapp.com",
  projectId: "discount-smoke-ce754",
  storageBucket: "discount-smoke-ce754.appspot.com",
  messagingSenderId: "83869270838",
  appId: "1:83869270838:web:f98e9936936f0c04166997"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const FireDB = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { FireDB, auth, storage }; // Export Storage along with FireDB and Auth
